export const color: any = {
  primary: '#00A3C7',
  primaryHighlight: '#007994',
  secondary: '#4CAF50',
  secondaryHighlight: '#45924A',
  red: '#D80054',
  redHover: '#9E003D',
  bg: '#F6F9FC',
  text: '#4a4a4a',
  brand: {
    blue: '#061b30',
    green: '#44C0FF',
    blueLight: '#37a1ce',
    orange: '#d84a34',
    grey: '#333333',
    white: '#ffffff',
    orangeActive: '#9F371D'
  },
  background: {
    grey: '#f4f4f4',
    white: 'white',
    blueBackground: '#f1fdfa',
    blueCookie: '#d9f7ff'
  },
  brandLight: {
    blue100: '#f5fcff',
    grey: '#e2e2e2',
    blue45: '#37a1ce'
  },
  support: {
    red: '#d0021b',
    green: '#4f9f31',
    yellow: '#f8e71c',
    radioBtnHover: 'rgba(6,27,48,0.8)'
  },
  keyline: {
    grey: '#e5e5e5',
    lineGreen: '#02ae86',
    black: '#101820'
  }
}

export default color
