export const appendUrlPartnerTracking = (url: string) => {
  let newUrl = url
  if (typeof window !== `undefined`) {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const partner = urlParams.get('partner')

    if (partner !== null) {
      newUrl += `?partner=${partner}`
    }
  }
  return newUrl
}

export const validateName = (name: string): boolean => {
  if (name.length > 30 || name.length < 1) {
    return false
  }
  return true
  // let re = /^[a-z ,.'-]+$/i
  // return re.test(String(name))
}

export const calculateUnitAmount = (prices: Array<any>, users: number) => {
  for (let i = 0; i < prices.length; i++) {
    if (prices[i].up_to) {
      if (users <= prices[i].up_to) {
        return prices[i].unit_amount / 100
      }
    } else {
      return prices[i].unit_amount / 100
    }
  }
}

export const numberWithCommas = (x: number): string => {
  if (typeof x === 'number') {
    return x.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }
  return x
}

export const scrollTo = async (id: string) => {
  const element = document.getElementById(id)
  if (element && element.scrollIntoView) {
    element.scrollIntoView({ behavior: 'smooth' })
  }
}

export const scrollPage = (id: string) => {
  if (typeof window !== 'undefined' && typeof document !== 'undefined') {
    const element = document.getElementById(id)
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' })
    }
  }
}

export const validateVat = (vat: string): boolean => {
  if (vat.toString().length !== 11) {
    return false
  }
  const arr: Array<number | string> = vat.toString().split('')

  if ((arr[0] + arr[1]).toUpperCase() !== 'GB') {
    return false
  }

  const numbers: Array<number | string> = arr.slice(3, 11)

  if (isNaN(Number(numbers.join('')))) {
    return false
  }

  return true
}

export const validateEmail = (email: string): boolean => {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

export const validatePhoneNumber = (phone: string | number): boolean => {
  if (!phone) {
    return true
  }
  const re = /^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?\#(\d{4}|\d{3}))?$/
  return re.test(phone.toString())
}

export const validatePostcode = (postcode: string): boolean => {
  const re = /([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})/
  return re.test(postcode)
}

export const validateOnBlur = (isValidated: boolean, toggleError: any): void => {
  if (isValidated) {
    toggleError(false)
  } else {
    toggleError(true)
  }
}

export const getItem = (property: string): string | null => {
  if (typeof window !== 'undefined') {
    return window.sessionStorage.getItem(property) || ''
  }
  return ''
}

export const setItem = (property: string, value: string): void => {
  if (typeof window !== 'undefined') {
    window.sessionStorage.setItem(property, value)
  }
}
