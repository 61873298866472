import * as React from 'react'
import Helmet from 'react-helmet'
import './layout.css'

import FormHeader from '../components/FormHeader'
import FormTimeline from '../components/FormTimeline'
import Faq from '../components/Faq'
import Footer from '../components/Footer'

import mail from '../images/mail.svg'
import confirmation from '../images/confirmation-tick.svg'

interface LayoutProps {
  heading: string
  body: string
  children: any
  currentPage: number
  resentEmail?: boolean
  title: string
  qs: Array<IQuestions>
}

interface IQuestions {
  question: string
  answer: React.ReactNode
}

const IndexLayout = ({ heading, body, currentPage, resentEmail, children, title, qs }: LayoutProps) => (
  <div>
    <Helmet title={title} meta={[{ name: 'description', content: "Bob's Business" }]} />
    <FormTimeline currentPage={currentPage} />
    <FormHeader heading={heading} body={body} confirmationPage={currentPage === 3} src={resentEmail ? mail : confirmation} />
    <div>{children}</div>
    {currentPage !== -1 && qs && <Faq questions={qs} />}
    <Footer />
  </div>
)

export default IndexLayout
