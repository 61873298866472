import React from 'react'
import styled from 'styled-components'
import theme from '../style/color'
import logo from '../images/bb-logo.svg'
import { appendUrlPartnerTracking } from '../util/functions'

interface NavigationProps {
  currentPage: number
}

const pages: Array<string> = ['Users', 'Account details', 'Billing Information', 'Start learning']

const Navigation = ({ currentPage }: NavigationProps) => {
  return (
    <Container>
      <a href={appendUrlPartnerTracking('/')}>
        <Logo src={logo} alt="Bob's Business logo" />
      </a>

      {currentPage !== -5 && (
        <Breadcrumb>
          {pages.map((page, i) => {
            return (
              <PageContainer key={i}>
                <PageTitle position={i} currentPage={currentPage}>
                  {page}
                </PageTitle>
                <Top position={i} currentPage={currentPage}>
                  {i === 0 && <Circle />}
                  <Line />
                  {(i === 1 || i === 2) && <Circle />}
                  <Line />
                  {i === 3 && <Circle />}
                </Top>
              </PageContainer>
            )
          })}
        </Breadcrumb>
      )}
    </Container>
  )
}

export default Navigation

const PageContainer = styled.div`
  flex: 1;
  align-items: center;
  display: flex;
  flex-direction: column;

  &:first-of-type,
  &:last-of-type {
    max-width: 18%;
  }
`

interface IBreadcrumbProps {
  position: number
  currentPage: number
}

const Top = styled.div<IBreadcrumbProps>`
  display: flex;
  align-items: center;
  width: 100%;

  & div:nth-child(1) {
    ${props => props.position === 0 && `background-color: ${theme.primary};border-color:${theme.primary};`}
    ${props => props.position === 1 && props.currentPage === 1 && `background-color: ${theme.primary};border-color:${theme.primary};`}
    ${props => props.currentPage === 2 && props.position !== 3 && `background-color: ${theme.primary};border-color:${theme.primary};`}
    ${props => props.currentPage === 3 && `background-color: ${theme.primary};border-color:${theme.primary};`}
  }
  & div:nth-child(2) {
    ${props => props.position === 1 && props.currentPage === 1 && `background-color: ${theme.primary};border-color:${theme.primary};`}
    ${props => props.position === 0 && props.currentPage === 1 && `background-color: ${theme.primary};`}
    ${props => props.currentPage === 2 && props.position !== 3 && `background-color: ${theme.primary};border-color:${theme.primary};`}
        ${props => props.currentPage === 3 && `background-color: ${theme.primary};border-color:${theme.primary};`}
  }
  & div:nth-child(3) {
    ${props => props.position === 0 && props.currentPage === 1 && `background-color: ${theme.primary};`}
    ${props => props.currentPage === 2 && props.position < 2 && `background-color: ${theme.primary};border-color:${theme.primary};`}
        ${props => props.currentPage === 3 && `background-color: ${theme.primary};border-color:${theme.primary};`}
  }
`

const Line = styled.div`
  height: 2px;
  width: 50%;
  background-color: #dadad9;
`

const Circle = styled.div`
  height: 10px;
  width: 10px;
  border: 2px solid #dadad9;
  border-radius: 50%;
  flex-shrink: 0;

  @media only screen and (max-width: 850px) {
    height: 12px;
    width: 12px;
  }
`

const PageTitle = styled.p<IBreadcrumbProps>`
  margin-top: 6px;
  font-size: 12px;
  line-height: 15px;
  font-weight: 300;
  text-align: center;
  color: #9b9b9b;
  margin-bottom: 10px;
  font-weight: 700;
  ${props => props.position === 0 && `align-self: flex-start`};
  ${props => props.position === 3 && `align-self: flex-end`};

  ${props => props.position <= props.currentPage && `color: ${theme.primary}`};
  ${props => props.position < props.currentPage && `font-weight:300`};

  @media only screen and (max-width: 1400px) {
    display: none;
  }
`

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 7%;
  padding-top: 35px;
  padding-bottom: 25px;

  @media only screen and (max-width: 850px) {
    margin: 0;
    padding: 12px 25px;
  }
`

const Logo = styled.img`
  width: 143px;
  margin-top: 5px;

  @media only screen and (max-width: 850px) {
    width: 100px;
  }
`
const Breadcrumb = styled.div`
  display: flex;
  width: 42%;
`
