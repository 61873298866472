import React, { useState } from 'react'
import styled from 'styled-components'
import arrow from '../images/down-arrow.svg'
import q from '../images/question.svg'
import theme from '../style/color'

interface QuestionProps {
  children: React.ReactNode
  question: string
  last?: boolean
}
interface ContainerProps {
  last?: boolean
}

const FaqQuestion = ({ question, children, last }: QuestionProps) => {
  const [isOpen, toggleAnswer] = useState(false)

  return (
    <Container last={last}>
      <Question onClick={() => toggleAnswer(!isOpen)}>
        <p>
          <img src={q} alt="q" />
          {question}
        </p>{' '}
        <FaqButton isOpen={isOpen} src={arrow} />
      </Question>
      <AnswerContainer isOpen={isOpen}>
        <Answer isOpen={isOpen}>{children}</Answer>
      </AnswerContainer>
    </Container>
  )
}

const FaqButton = styled.img`
  width: 18px;
  transition: all 0.2s ease;
  ${props => props.isOpen && `transform: rotate(180deg)`};
`

const AnswerContainer = styled.div<IAnswerProps>`
  max-height: 0;
  overflow: hidden;
  transition: all 0.5s ease-out;
  transition-delay: 0;

  ${props => props.isOpen && `max-height: 200px`}
`

const Container = styled.div<ContainerProps>`
  border-bottom: 1px solid #eee;
  padding-top: 50px;
  padding-bottom: 45px;
  width: 100%;

  &:nth-of-type(1) {
    border-top: 1px solid #eee;
  }

  @media only screen and (max-width: 850px) {
    padding-top: 30px;
    padding-bottom: 23px;
  }
`

const Question = styled.div`
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 8px;
  text-decoration: none;
  color: #0b1033;
  font-size: 18px;
  line-height: 24px;
  font-weight: 300;
  cursor: pointer;
  padding-left: 30px;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;

  & p {
    display: flex;
    font-weight: bold;
    color: ${theme.primary};

    @media (max-width: 600px) {
      align-items: flex-start;
    }
    & img {
      width: 23px;
      margin-right: 10px;
    }
  }

  @media (max-width: 1023px) {
    font-size: 15px;
    line-height: 25px;
    padding: 14px 20px;

    & p {
      width: 80%;
    }
  }

  @media (max-width: 850px) {
    padding: 0;
  }
`

interface IAnswerProps {
  isOpen: boolean
}

const Answer = styled.div<IAnswerProps>`
  padding: 42px 0;

  @media only screen and (max-width: 1024px) {
    padding: 20px 0 0;
  }

  & p {
    margin-right: 25px;
    margin-bottom: 0px;
    margin-left: 25px;
    color: #64646d;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: -0.25px;
    font-weight: 300;
    @media (max-width: 1023px) {
      font-size: 15px;
      line-height: 25px;
    }
  }

  @media (max-width: 1023px) {
    ${props =>
      props.isOpen &&
      `
      padding: 24px 0;
      `}
  }
`

export default FaqQuestion
