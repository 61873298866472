import React from 'react'
import styled from 'styled-components'
import FaqQuestion from './FaqQuestion'
import qa from '../images/qa.svg'
import theme from '../style/color'

interface IQuestions {
  question: string
  answer: React.ReactNode
}

interface FaqProps {
  questions: Array<IQuestions>
}

const Faq = ({ questions }: FaqProps) => {
  return (
    <Container>
      <InnerContainer>
        <FaqIcon src={qa} />
        <Heading>Any questions? Here are some answers.</Heading>
        {questions.map((question, i) => {
          return (
            <FaqQuestion question={question.question} key={i} last={i >= questions.length - 1}>
              {question.answer}
            </FaqQuestion>
          )
        })}
      </InnerContainer>
    </Container>
  )
}

export default Faq

const InnerContainer = styled.div`
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;

  @media (max-width: 1200px) {
    padding: 0 25px;
    width: 100%;
  }
`

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 20;
  padding: 100px 0 20px;
  background-color: #fafafa;
  background-position: 0px 0px;
  background-size: 6px 6px;
  @media (max-width: 1200px) {
    padding: 80px 0 20px;
  }
  @media (max-width: 1023px) {
    padding: 60px 0 20px;
  }
  @media (max-width: 767px) {
    padding: 40px 0 20px;
  }
`
const FaqIcon = styled.img`
  width: 120px;
  margin-bottom: 15px;
`

const Heading = styled.h1`
  margin-bottom: 50px;
  font-size: 36px;
  line-height: 36px;
  font-weight: 300;
  text-align: center;

  @media (max-width: 1024px) {
    font-size: 25px;
    line-height: 35px;
    margin-bottom: 30px;
  }

  @media (max-width: 850px) {
    font-size: 16px;

    line-height: 19px;
  }
`
