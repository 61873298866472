import React from 'react'
import styled from 'styled-components'
import theme from '../style/color'

interface BtnPrimaryProps {
  onClick: any
  type: string
  children: React.ReactNode
  href?: string
  target?: string
}

const BtnPrimarySquare = ({ onClick, children, type = 'primary', href, target }: BtnPrimaryProps) => {
  return (
    <Container onClick={onClick} type={type} href={href} target={target}>
      {children}
    </Container>
  )
}

export default BtnPrimarySquare

interface ContainerProps {
  type: string
}

const Container = styled.a<ContainerProps>`
  display: inline-block;
  text-decoration: none;
  border: 1px solid ${theme.red};
  border: 1px solid ${props => props.type === 'secondary' && theme.red};
  border-radius: 4px;
  background-color: white;
  background-color: ${props => props.type === 'secondary' && theme.red};
  color: ${theme.red} !important;
  color: ${props => props.type === 'secondary' && 'white'} !important;
  text-align: center;
  font-size: 18px !important;
  line-height: 36px !important;
  padding: 10px 60px;
  margin-bottom: 10px;
  cursor: pointer;
  transition: background-color 200ms ease, color 200ms ease, background-color 200ms ease, transform 200ms ease, -webkit-transform 200ms ease;

  @media only screen and (max-width: 850px) {
    width: 100%;
  }

  @media only screen and (max-width: 365px) {
    font-size: 17px !important;
  }
  &:visited,
  &:link {
    text-decoration: none;
  }

  &:hover,
  &:hover div {
    background-color: ${props => (props.type === 'secondary' ? theme.redHover : theme.red)};
    color: white !important;
  }
`
