import React from 'react'
import styled from 'styled-components'
import theme from '../style/color'

const FormDivider = ({ heading }) => {
  return (
    <Container>
      <p>{heading}</p>
      <Line />
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  & p {
    color: ${theme.primary};
    font-size: 15px;
    font-weight: bold;
    line-height: 20px;
  }
`

const Line = styled.div`
  flex: 1;
  height: 1px;
  width: 100%;
  background: ${theme.primary};
  margin-left: 20px;
`

export default FormDivider
