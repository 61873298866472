import React from 'react'
import styled from 'styled-components'
import theme from '../style/color'

interface FormHeaderProps {
  heading: string
  body: string
  confirmationPage: boolean
  src: string
}

const FormHeader = ({ heading, body, confirmationPage, src }: FormHeaderProps) => {
  return heading && (body || confirmationPage) ? (
    <OuterContainer>
      <Container confirmationPage={confirmationPage}>
        {confirmationPage && <Image src={src} alt="Sent email" />}
        <Heading>{heading}</Heading>
        <Body>{body}</Body>
      </Container>
    </OuterContainer>
  ) : (
    <></>
  )
}

const Image = styled.img`
  height: 120px;
  margin-bottom: 25px;

  @media only screen and (max-width: 850px) {
    height: 100px;
    margin-bottom: 20px;
  }
`

const OuterContainer = styled.div`
  background: #f6f9fc;
`

const Container = styled.div`
  width: 92%;
  max-width: 1140px;
  margin: 0 auto;
  padding: 0 20px;
  position: relative;
  align-items: flex-start;
  padding-bottom: 40px;
  padding-top: 70px;

  ${props =>
    props.confirmationPage &&
    `
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
  `};

  @media only screen and (max-width: 850px) {
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 36px;
    padding-top: 53px;
    max-width: 100%;
    width: 100%;
    margin: 0;
  }
`

const Heading = styled.h1`
  font-size: 60px;
  font-weight: 900;
  line-height: 81px;

  @media only screen and (max-width: 850px) {
    font-size: 30px;
    line-height: 40px;
  }
`

const Body = styled.p`
  color: black;
  font-size: 20px;
  font-weight: 300;
  line-height: 27px;

  @media only screen and (max-width: 850px) {
    font-size: 16px;
    line-height: 26px;
  }
`

export default FormHeader
