import React from 'react'
import styled from 'styled-components'
import theme from '../style/color'
import logo from '../images/bb-logo_footer.svg'
import { appendUrlPartnerTracking } from '../util/functions'

interface FooterProps {
  children: React.ReactNode
}
const Footer = () => {
  return (
    <Container>
      <InnerContainer>
        <img src={logo} alt="logo" />
        <TextContainer>
          <LinksContainer>
            <a target="_blank" href={appendUrlPartnerTracking('/terms-and-conditions/')}>
              Terms & Conditions
            </a>
            <Separator>|</Separator>
            <a target="_blank" href={appendUrlPartnerTracking('/privacy-policy/')}>
              Privacy policy
            </a>
          </LinksContainer>
          <strong>The legal bit.</strong> Etiam nec lacinia justo. Etiam sodales augue nec ultricies hendrerit. Aliquam erat volutpat. Nunc
          in felis ut lorem rutrum bibendum. Vivamus elementum arcu ut tempor facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing
          elit. Praesent vestibulum erat nec dictum pretium. Donec viverra aliquam nulla, porta molestie justo laoreet et. Curabitur ut
          condimentum purus.
        </TextContainer>
      </InnerContainer>
    </Container>
  )
}

export default Footer

const InnerContainer = styled.div`
  position: relative;
  display: block;
  max-width: 1100px;
  width: 100%;
  margin: 0 auto;
  font-size: 15px;

  & img {
    width: 70px;
    margin-bottom: 25px;
  }
  @media (max-width: 1200px) {
    padding: 0 25px;
  }
`

const Container = styled.div`
  position: relative;
  z-index: 1;
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 40px;
  padding-bottom: 90px;
  background-color: #fafafa;
`

const TextContainer = styled.div`
  color: ${theme.text};
  font-size: 12px;
  line-height: 20px;
  font-weight: 300;

  & b,
  & strong {
    font-weight: bold;
  }
`
const LinksContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  & a {
    color: ${theme.text};
    font-size: 13px;
    line-height: 21px;
  }
`

const Separator = styled.div`
  margin: 0 10px;
`
